import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// constantes
import {
  EXITO_NECESITAS_MODIFICAR,
  getTextoConfirmadaPara,
  EXITO_VIDEOLLAMADA,
  TIEMPO_Y_FORMA_CONFIRMACION,
  TITULO_EVENTO_CALENDARIO,
  AGREGAR_CALENDARIO,
  DESCRIPCION_EVENTO,
  DURACION_ESTIMADA,
  INSTRUCCIONES_INICIO_SESION_INICIO,
  INSTRUCCIONES_INICIO_SESION_FIN,
  INSTRUCCIONES_INICIO_SESION_IMPORTANTE,
  HORA_URUGUAY,
  TIPO_TURNO_CONSULTA,
} from 'constants/commonConstants';

// componentes
import TitleCard from 'components/TitleCard';
import TextoMultiformato from 'components/TextoMultiformato';
import IconoAgregarAlCalendario from 'components/IconoAgregarAlCalendario';

// componentes internos
import ContextoAgenda from '../ContextoAgenda';

// estilos
import styles from './ExitoReserva.module.css';

const ExitoReserva = ({ tipoTurno }) => {
  const { fechaSeleccionada, disponibilidadSeleccionada } =
    useContext(ContextoAgenda);

  return (
    <div className={styles.container}>
      <TitleCard className={styles.titulo} />
      <div className={styles.body}>
        <TextoMultiformato
          className={styles.paragraph}
          key="videollamadaAgendadaFecha"
          listaNormal={[EXITO_VIDEOLLAMADA, ` ${HORA_URUGUAY}.`]}
          listaNegrita={[
            getTextoConfirmadaPara(
              fechaSeleccionada,
              disponibilidadSeleccionada?.value,
            ),
          ]}
        />
        {tipoTurno !== TIPO_TURNO_CONSULTA && (
          <TextoMultiformato
            className={styles.paragraph}
            key="videollamadaAgendada"
            listaNormal={[
              INSTRUCCIONES_INICIO_SESION_INICIO,
              INSTRUCCIONES_INICIO_SESION_FIN,
            ]}
            listaNegrita={[INSTRUCCIONES_INICIO_SESION_IMPORTANTE]}
          />
        )}
        <div>{DURACION_ESTIMADA}</div>
        <div>{EXITO_NECESITAS_MODIFICAR}</div>
        <div>{TIEMPO_Y_FORMA_CONFIRMACION}</div>
        <div>{AGREGAR_CALENDARIO}</div>
      </div>
      <div className={styles.agregarCalendariosContenedor}>
        <IconoAgregarAlCalendario
          nombreEvento={TITULO_EVENTO_CALENDARIO}
          datetimeEvento={disponibilidadSeleccionada?.value}
          descripcionEvento={DESCRIPCION_EVENTO}
          tipo="google"
        />
        <IconoAgregarAlCalendario
          nombreEvento={TITULO_EVENTO_CALENDARIO}
          datetimeEvento={disponibilidadSeleccionada?.value}
          descripcionEvento={DESCRIPCION_EVENTO}
          tipo="apple"
        />
        <IconoAgregarAlCalendario
          nombreEvento={TITULO_EVENTO_CALENDARIO}
          datetimeEvento={disponibilidadSeleccionada?.value}
          descripcionEvento={DESCRIPCION_EVENTO}
          tipo="outlook"
        />
      </div>
    </div>
  );
};

ExitoReserva.propTypes = {
  tipoTurno: PropTypes.string,
};

export default ExitoReserva;
