import dayjs from 'dayjs';

export const craftVideollamadaConsultaPayload = (
  informacionContacto,
  fecha,
  disponibilidadSeleccionada,
) => ({
  informacion_contacto: {
    nombre_completo: informacionContacto.nombre_completo,
    numero_documento: informacionContacto.numero_documento,
    correo_electronico: informacionContacto.email,
    numero_telefono: informacionContacto.telefono,
    asunto: informacionContacto.asunto,
    mensaje: informacionContacto.mensaje,
  },
  fecha: dayjs(fecha).format('YYYY-MM-DD'),
  hora: dayjs(disponibilidadSeleccionada.value).format('HH:mm:ss'),
});
