import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CardSmall from 'components/CardSmall';
import CardSpinner from 'components/CardSpinner';
import { CONTACT_VIDEOLLAMADA_CONFIRM } from 'constants/pageTitles';
import { confirmarConsultaVideollamada } from 'services/apiServices';
import ButtonSection from 'components/ButtonSection';
import { ERROR_ROUTE, INDEX_ROUTE } from 'constants/RouterConstants';
import {
  VIDEOLLAMADA_CONFIRMADA,
  VIDEOLLAMADA_CONFIRMADA_NOTIFICACION,
  VOLVER_AL_INICIO,
} from 'constants/commonConstants';

const ConfirmacionConsulta = () => {
  const [cargando, setCargando] = useState(true);
  const [errors, setErrors] = useState(false);
  const history = useHistory();

  const handleVolverAlInicio = () => {
    history.push(INDEX_ROUTE);
  };

  const confirmarVideollamada = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const uuid = urlSearchParams.get('videollamada');
    try {
      await confirmarConsultaVideollamada(uuid);
    } catch (error) {
      setErrors(true);
    }
  };

  useEffect(() => {
    confirmarVideollamada();
    setCargando(false);
  }, []);

  if (cargando) {
    return <CardSpinner spin />;
  }

  if (errors) {
    history.push(ERROR_ROUTE);
  }

  return (
    <>
      <Helmet title={CONTACT_VIDEOLLAMADA_CONFIRM} />
      <CardSmall
        title={VIDEOLLAMADA_CONFIRMADA}
        desc={VIDEOLLAMADA_CONFIRMADA_NOTIFICACION}
      >
        <ButtonSection
          textPrimary={VOLVER_AL_INICIO}
          handleClickPrimary={handleVolverAlInicio}
        />
      </CardSmall>
    </>
  );
};

export default ConfirmacionConsulta;
