// ---------------------------------------------------------------------------
// Navegación
// ---------------------------------------------------------------------------
export const INDEX_ROUTE = '/';

// ---------------------------------------------------------------------------
// Login
// ---------------------------------------------------------------------------
export const LOGIN_PROCESS = '/login-process';
export const LOGIN_ROUTE = '/login';

// ---------------------------------------------------------------------------
// Logout
// ---------------------------------------------------------------------------
export const LOGOUT_PROCESS = '/logout-process';

// ---------------------------------------------------------------------------
// Consentimiento
// ---------------------------------------------------------------------------
export const AUTORIZACION_ROUTE = '/autorizacion';

// ---------------------------------------------------------------------------
// Seguridad
// ---------------------------------------------------------------------------
export const SEGURIDAD_ROUTE = '/seguridad';

// ---------------------------------------------------------------------------
// Seguridad - contraseña
// ---------------------------------------------------------------------------
const SEGURIDAD_CONTRASEÑA_ROUTE = `${SEGURIDAD_ROUTE}/contraseña`;
export const VERIFY_PASSWORD_ROUTE = `${SEGURIDAD_CONTRASEÑA_ROUTE}/verificar`;
export const CHANGE_PASSWORD_ROUTE = `${SEGURIDAD_CONTRASEÑA_ROUTE}/modificar`;
export const CHANGE_PASSWORD_ROUTE_SUCCESS = `${CHANGE_PASSWORD_ROUTE}/exito`;

// ---------------------------------------------------------------------------
// Seguridad - correo electrónico
// ---------------------------------------------------------------------------
const SEGURIDAD_CORREO_ELECTRONICO_ROUTE = `${SEGURIDAD_ROUTE}/correo-electronico`;
export const RESENDED_ACTIVATION_EMAIL_LOGGED = `${SEGURIDAD_CORREO_ELECTRONICO_ROUTE}/reenviar-link-activacion`;
export const CHANGE_USER_EMAIL_ROUTE = `${SEGURIDAD_CORREO_ELECTRONICO_ROUTE}/modificar`;
export const CHANGE_USER_EMAIL_ROUTE_SUCCESS = `${CHANGE_USER_EMAIL_ROUTE}/link-enviado`;
export const CHANGE_USER_EMAIL_APPROVE_ROUTE = `${CHANGE_USER_EMAIL_ROUTE}/aprobar`;
export const CHANGE_USER_EMAIL_VALIDATED_ROUTE = `${CHANGE_USER_EMAIL_APPROVE_ROUTE}/ok`;
export const CHANGE_USER_EMAIL_ERROR_ROUTE = `${CHANGE_USER_EMAIL_APPROVE_ROUTE}/error`;

// ---------------------------------------------------------------------------
// Seguridad - Teléfono
// ---------------------------------------------------------------------------
const SEGURIDAD_TELEFONO_ROUTE = `${SEGURIDAD_ROUTE}/telefono`;
export const CAMBIAR_TELEFONO_ROUTE = `${SEGURIDAD_TELEFONO_ROUTE}/modificar`;
export const CAMBIAR_TELEFONO_ROUTE_EXITO = `${CAMBIAR_TELEFONO_ROUTE}/exito`;
export const CAMBIAR_TELEFONO_ROUTE_ERROR = `${SEGURIDAD_TELEFONO_ROUTE}/error`;

// ---------------------------------------------------------------------------
// Seguridad - autenticación en dos pasos
// ---------------------------------------------------------------------------
const SEGURIDAD_AUTENTICACION_EN_DOS_PASOS_ROUTE = `${SEGURIDAD_ROUTE}/autenticacion-en-dos-pasos`;
export const MFA_PAGE_ROUTE = `${SEGURIDAD_AUTENTICACION_EN_DOS_PASOS_ROUTE}/totp`;
export const MFA_CONFIG_TOTP_ROUTE = `${MFA_PAGE_ROUTE}/config`;
export const MFA_VERIFY_CODE_TOTP_ROUTE = `${MFA_PAGE_ROUTE}/activar`;
export const MFA_VERIFY_CODE_SUCCESS_TOTP_ROUTE = `${MFA_VERIFY_CODE_TOTP_ROUTE}/exito`;

// ---------------------------------------------------------------------------
// Seguridad - ingreso videollamada
// ---------------------------------------------------------------------------
export const INGRESO_VIDEOLLAMADA = '/ingresar-videollamada';
export const INGRESO_VIDEOLLAMADA_SOPORTE = '/ingresar-videollamada-soporte';

// ---------------------------------------------------------------------------
// Seguridad - Acceso desde IDP
// ---------------------------------------------------------------------------
export const AUTOGESTION_IDP = '/seguridad-idp';

// ---------------------------------------------------------------------------
// Registro
// ---------------------------------------------------------------------------
export const REGISTRO_ROUTE = '/registro';
export const REGISTRO_SENDED_ACTIVATION_EMAIL = `${REGISTRO_ROUTE}/exito`;
export const REGISTRO_REENVIAR_CORREO_ACTIVACION = `${REGISTRO_ROUTE}/reenviar-link-activacion`;
export const REGISTRO_RESENDED_ACTIVATION_EMAIL = `${REGISTRO_REENVIAR_CORREO_ACTIVACION}/exito`;
export const REGISTRO_ACTIVATE_ACCOUNT = `${REGISTRO_ROUTE}/activar-cuenta`;
export const REGISTRO_ACTIVATION_SUCCESS = `${REGISTRO_ACTIVATE_ACCOUNT}/cuenta-activada`;
export const REGISTRO_ACTIVATION_ERROR = `${REGISTRO_ACTIVATE_ACCOUNT}/error`;

// ---------------------------------------------------------------------------
// Contraseña (usuario deslogueado)
// ---------------------------------------------------------------------------
const PASSWORD_LOGGED_OUT_ROUTE = '/contraseña';
// restablecer
export const RESET_PASSWORD_ROUTE = `${PASSWORD_LOGGED_OUT_ROUTE}/restablecer`;
export const RESET_PASSWORD_SENT_ROUTE = `${RESET_PASSWORD_ROUTE}/link-enviado`;
export const RESET_PASSWORD_NEW_PASSWORD_ROUTE = `${RESET_PASSWORD_ROUTE}/nueva-contraseña`;
export const RESET_PASSWORD_INVALID_LINK_ROUTE = `${RESET_PASSWORD_ROUTE}/link-invalido`;

// ---------------------------------------------------------------------------
// Páginas de error
// ---------------------------------------------------------------------------
export const ERROR_ROUTE = '/error';
export const UNAVAILABLE_ROUTE = '/desconocido';

// ---------------------------------------------------------------------------
// SAML
// ---------------------------------------------------------------------------
export const SAML_RESPONSE_ROUTE = '/sending-saml-response';
export const SAML_LOGOUT_ROUTE = '/logout-saml';

// ---------------------------------------------------------------------------
// Pruebas SAML
// ---------------------------------------------------------------------------
export const SAML_INDEX_ROUTE = '/saml-index';

// ---------------------------------------------------------------------------
// Paginas externas gub.uy
// ---------------------------------------------------------------------------
export const HOME_ROUTE = 'https://www.gub.uy/';
export const CONOCE_MAS_ROUTE =
  'https://www.gub.uy/agencia-gobierno-electronico-sociedad-informacion-conocimiento/usuario';

// ---------------------------------------------------------------------------
// Páginas estáticas
// ---------------------------------------------------------------------------
export const TERMS_N_CONDS_ROUTE = '/terminos-y-condiciones';
// export const DECLARACION_ACCESIBILIDAD_ROUTE = '/declaracion-accesibilidad';
export const PRIVACIDAD_ROUTE = '/politica-de-privacidad';
export const ACERCA_DE_ROUTE = '/acerca-de';
export const METODO_USUARIO_GUB_UY = '/metodo-usuario-gubuy';
export const METODO_ID_DIGITAL_ABITAB = '/metodo-id-digital-abitab';
export const METODO_TUID_ANTEL = '/metodo-tuid-antel';
export const METODO_CI_CON_CHIP = '/metodo-ci-con-chip';
export const METODOS_DISPONIBLES_ROUTE = '/metodos-disponibles-diferencias';
export const MAS_INFORMACION_METODOS =
  'https://www.gub.uy/agencia-gobierno-electronico-sociedad-informacion-conocimiento/politicas-y-gestion/identificacion-segura-confiable';

// ---------------------------------------------------------------------------
// Contacto
// ---------------------------------------------------------------------------
export const CONTACTO_ROUTE = '/contacto';
export const ENVIO_MENSAJE_CONTACTO_SUCCESS = '/contacto/mensaje-enviado';
// ---------------------------------------------------------------------------
// Contacto Videollamada
// ---------------------------------------------------------------------------
export const CONTACTO_VIDEOLLAMADA_ROUTE = '/contacto-videollamada';

// ---------------------------------------------------------------------------
// Ayuda
// ---------------------------------------------------------------------------
export const AYUDA_ROUTE = '/ayuda';
export const AYUDA_DETAIL_ROUTE = '/ayuda/:slug';

// ---------------------------------------------------------------------------
// Faqs usadas
// ---------------------------------------------------------------------------
export const FAQ_AUMENTAR_GARANTIA_IDENTIDAD =
  '/ayuda/que-es-la-garantia-de-identidad';
export const FAQ_APLICACIONES = '/ayuda/aplicaciones';
export const FAQ_ACTUALIZACION_DATOS_IDP = '/ayuda/actualizacion-de-datos-idp';
export const FAQ_VALIDAR_IDENTIDAD =
  '/ayuda/como-puedo-validar-mi-identidad-si-realice-un-registro-web';
export const FAQ_ACTIVAR_TOTP =
  '/ayuda/como-activo-la-verificacion-en-dos-pasos';
// ---------------------------------------------------------------------------
// Feedback
// ---------------------------------------------------------------------------
export const FEEDBACK_FORM_ROUTE = '/feedback';
export const ENVIO_FEEDBACK_FORM_SUCCESS = '/feedback/mensaje-enviado';

// ---------------------------------------------------------------------------
// Links externos
// ---------------------------------------------------------------------------

export const PUNTOS_ATENCION_CIUDADANIA =
  'https://www.gub.uy/politicas-y-gestion/puntos-atencion-ciudadania-departamento';

// ---------------------------------------------------------------------------
// Videollamada
// ---------------------------------------------------------------------------

export const AGENDAR_VIDEOLLAMADA = '/videollamada';
export const VIDEOLLAMADA_CANCELADA = '/videollamada-cancelada';
export const CONFIRMACION_CANCELAR_VIDEOLLAMADA =
  '/confirmar-cancelar-videollamada';
export const CONFIRMAR_ELEVACION_VIDEOLLAMADA =
  '/confirmar-nivel-intermedio-videollamada';
export const VIDEOLLAMADA_SOPORTE = '/videollamada-soporte';
export const CANCELAR_VIDEOLLAMADA_SOPORTE = '/cancelar-videollamada-soporte';

// ---------------------------------------------------------------------------
// Segundo factor en login
// ---------------------------------------------------------------------------
export const CONFIGURAR_SEGUNDO_FACTOR_LOGIN = '/configurar-segundo-factor';
