import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { JitsiMeeting } from '@jitsi/react-sdk';

// Componentes
import Card from 'components/Card';
import CardSpinner from 'components/CardSpinner';

import { fetchVideollamadaSoporte } from 'services/apiServices';

// Estilos
import styles from '../ingreso_videollamada/ingresoVideollamada.module.css';

export const IngresoVideollamadaSoporte = () => {
  const [cargando, setCargando] = useState(true);
  const [videollamada, setVideollamada] = useState(null);
  const { search } = useLocation();

  const getVideollamadaUuid = () => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('uuid');
  };

  const handleObtenerVideollamada = async () => {
    const uuid = getVideollamadaUuid();
    try {
      const response = await fetchVideollamadaSoporte(uuid);
      setVideollamada(response.data);
      setCargando(false);
    } catch (e) {
      setVideollamada(false);
    }
  };

  const toInicio = () => {
    window.location.href = '/';
  };

  useEffect(() => {
    handleObtenerVideollamada();
  }, []);

  if (cargando) {
    return <CardSpinner />;
  }

  return (
    <Card>
      <JitsiMeeting
        domain={window.REACT_APP_DOMINIO_VIDEOLLAMADA}
        roomName={videollamada.link}
        jwt={videollamada?.jwt}
        getIFrameRef={n => {
          n.classList.add(styles.iframeVideollamada);
        }}
        configOverwrite={{
          defaultLanguage: 'es',
          disableDeepLinking: true,
        }}
        userInfo={{
          displayName: videollamada.informacion_contacto.nombre_completo,
        }}
        onApiReady={externalApi => {
          externalApi.addListener('videoConferenceLeft', toInicio);
        }}
      />
    </Card>
  );
};
