/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// api services
import {
  validarInfoDnic,
  obtenerDiasDisponibles,
  crearTramiteVideollamada,
  obtenerHorasPorFecha,
} from 'services/apiServices';

// actions
import { fetchMisDatos } from 'actions/misDatos';
import { buildErrorUrl } from 'utils/utils';

// constantes
import {
  FLUJO_VIDEOLLAMADA_CONTINUAR,
  FLUJO_VIDEOLLAMADA_DATOS_PERSONALES,
  FLUJO_VIDEOLLAMADA_AGENDA,
  FLUJO_VIDEOLLAMADA_CONFIRMACION,
  IR_A_MI_PERFIL,
  FLUJO_VIDEOLLAMADA_CONFIRMAR_AGENDA,
  TIMEZONE_MONTEVIDEO,
} from 'constants/commonConstants.js';

// componentes
import Stepper from 'components/Stepper';
import ButtonSolid from 'components/ButtonSolid';
import ButtonOutline from 'components/ButtonOutline';
import CardSpinner from 'components/CardSpinner';

// componentes internos
import { datosUsuarioLogueado } from 'constants/selectors';
import Confirmacion from '../Confirmacion';
import ContextoAgenda from '../ContextoAgenda';
import Requisitos from '../Requisitos';
import DatosPersonales from '../DatosPersonales';
import PasoAgenda from '../PasoAgenda';
import ExitoReserva from '../ExitoReserva';

import styles from './Agenda.module.css';

const Agenda = () => {
  const dispatch = useDispatch();
  const usuarioLogueado = useSelector(datosUsuarioLogueado);

  const [pasoActual, setPasoActual] = useState(0);
  const [aceptaCondiciones, setAceptaCondiciones] = useState({
    terminos: false,
    clausula: false,
  });
  const [aceptaGuardarNumeroDeSerie, setAceptaGuardarNumeroDeSerie] =
    useState(false);
  const [datosPersonales, setDatosPersonales] = useState({
    numeroSerie: '',
    numeroTelefono: usuarioLogueado?.numero_telefono || '',
  });
  // Pasos completos es un arreglo que informa que pasos [0, 1, 2, 3] estan completos
  const [pasosCompletos, setPasosCompletos] = useState([
    false,
    false,
    false,
    false,
  ]);
  // Errores del Paso 1
  const [erroresPaso1, setErroresPaso1] = useState({
    primerApellido: false,
    numeroSerie: false,
    pedidoFallido: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  // Estado para checkear si se tiene que llamar o no al endpoint de la DNIC en el paso 1
  const [llamarDnic, setLlamarDnic] = useState(true);
  // Paso dos
  const [fechasDisponibles, setFechasDisponibles] = useState([]);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
  const [horasDisponibles, setHorasDisponibles] = useState([]);
  const [disponibilidadSeleccionada, setDisponibilidadSeleccionada] =
    useState(null);
  const [errorCupos, setErrorCupos] = useState(false);
  const [errorVideollamadaAgendada, setErrorVideollamadaAgendada] =
    useState(false);
  const [botonCargando, setBotonCargando] = useState(false);

  const history = useHistory();

  useEffect(() => {
    history.listen(location => {
      if (location?.state) {
        if ('paso' in location.state) {
          setPasoActual(location.state.paso);
        } else if ('success' in location.state) {
          setPasoActual(4);
        }
      } else {
        setPasoActual(0);
      }
    });
  }, [history.location]);

  const actualizarLink = nuevoPaso => {
    history.push({
      pathname: '',
      hash: nuevoPaso === 4 ? '#exito ' : `#paso${nuevoPaso}`,
      state: {
        paso: nuevoPaso,
      },
    });
  };

  const obtenerFechasPasoDos = async () => {
    setIsLoading(true);
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const fechaActualUruguay = dayjs().tz(TIMEZONE_MONTEVIDEO);
    const fechaInicio = fechaActualUruguay.add(1, 'day').format('YYYY-MM-DD');
    const fechaFin = fechaActualUruguay.add(3, 'month').format('YYYY-MM-DD');

    try {
      const response = await obtenerDiasDisponibles(fechaInicio, fechaFin);
      const fechas = response.data.reduce((diasDisponibles, dia) => {
        if (dia.disponible) {
          // Dato importante:
          // Aquí se trae del backend las fechas disponibles entre mañana y 3 meses (hora Uruguay).
          // Sin embargo, en la siguiente línea, el objeto date se crea con el timezone del individuo.
          // Motivo:
          // Hoy en Uruguay es 26 de Septiembre, por lo tanto al backend se le solicita
          // las fechas disponibles entre el 27/09 y el 26/12.
          // El calendario recibe objetos Date de Javascript, en el timezone de la persona.
          // Como quiero que el calendario le muestre 27/09, 28/09, etc, genero el día 27/09 en
          // su timezone.
          // Al capturar la fecha seleccionada en el calendar, se pasa al backend solo el valor de fecha,
          // sin el timezone, Ej: 2023/09/27. Por lo que no se presentan inconvenientes.
          diasDisponibles.push(dayjs(dia.fecha).toDate());
        }
        return diasDisponibles;
      }, []);
      setFechasDisponibles(fechas);
      setFechaSeleccionada(fechas[0]);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const verificarInfoPrimerPaso = async () => {
    const data = {
      primer_apellido: usuarioLogueado.primer_apellido,
      numero_serie: datosPersonales.numeroSerie,
      cedula: usuarioLogueado.numero_documento,
    };
    setIsLoading(true);
    try {
      const response = await validarInfoDnic(data);
      const datosVerificados = response.data;
      setErroresPaso1({
        primerApellido: datosVerificados?.primer_apellido === false,
        numeroSerie: datosVerificados?.numero_serie === false,
        pedidoFallido: false,
        esMayorDeEdad: datosVerificados?.mayor_de_edad === false,
      });
      setIsLoading(false);
      if (
        datosVerificados.numero_serie &&
        datosVerificados.primer_apellido &&
        datosVerificados.mayor_de_edad
      ) {
        setLlamarDnic(false);
        actualizarLink(pasoActual + 1);
        setPasoActual(pasoActual + 1);
        obtenerFechasPasoDos();
      }
    } catch {
      setErroresPaso1({
        primerApellido: false,
        numeroSerie: false,
        pedidoFallido: true,
        esMayorDeEdad: false,
      });
      setIsLoading(false);
    }
  };

  const pasoSiguiente = () => {
    if (pasoActual === 1 && llamarDnic) {
      verificarInfoPrimerPaso();
    } else if (pasoActual < 4 && pasosCompletos[pasoActual]) {
      actualizarLink(pasoActual + 1);
      setPasoActual(pasoActual + 1);
    }
  };

  const cambiarPaso = nuevoPaso => {
    if (nuevoPaso < pasoActual) {
      actualizarLink(nuevoPaso);
      setPasoActual(nuevoPaso);
    }
  };

  const handleAceptaCondiciones = condiciones => {
    setAceptaCondiciones(condiciones);

    const copiaPasosCompletos = [...pasosCompletos];
    copiaPasosCompletos[0] = condiciones.terminos && condiciones.clausula;
    setPasosCompletos(copiaPasosCompletos);
  };

  const handleAceptaGuardarNumeroDeSerie = () => {
    setAceptaGuardarNumeroDeSerie(!aceptaGuardarNumeroDeSerie);
  };

  useEffect(() => {
    const datosCompletos =
      (fechaSeleccionada && disponibilidadSeleccionada) !== null;
    const copiaPasosCompletos = [...pasosCompletos];
    copiaPasosCompletos[2] = datosCompletos;
    copiaPasosCompletos[3] = datosCompletos;
    setPasosCompletos(copiaPasosCompletos);
  }, [disponibilidadSeleccionada]);

  const pasos = [
    {
      id: 1,
      stepText: `${FLUJO_VIDEOLLAMADA_DATOS_PERSONALES}`,
    },
    {
      id: 2,
      stepText: `${FLUJO_VIDEOLLAMADA_AGENDA}`,
    },
    {
      id: 3,
      stepText: `${FLUJO_VIDEOLLAMADA_CONFIRMACION}`,
    },
  ];

  const confirmarReserva = async () => {
    try {
      setBotonCargando(true);
      setErrorCupos(false);
      setErrorVideollamadaAgendada(false);
      let datosTramite = {
        fecha: dayjs(fechaSeleccionada).format('YYYY-MM-DD'),
        hora: dayjs(disponibilidadSeleccionada.value).format('HH:mm:ss'),
        numero_telefono: datosPersonales.numeroTelefono,
      };
      if (aceptaGuardarNumeroDeSerie) {
        datosTramite = {
          ...datosTramite,
          numero_serie: datosPersonales.numeroSerie,
        };
      }
      await crearTramiteVideollamada(datosTramite);
      setBotonCargando(false);
      pasoSiguiente();
    } catch (error) {
      setBotonCargando(false);
      if (error?.data === 'Este cupo ya fue ocupado') setErrorCupos(true);
      else if (
        error?.data ===
        'El ciudadano ya tiene un trámite de videollamada en curso'
      )
        setErrorVideollamadaAgendada(true);
      else history.push(buildErrorUrl('SERVER_ERROR'));
    }
  };

  const generarTextoBoton = () =>
    pasoActual === 3
      ? FLUJO_VIDEOLLAMADA_CONFIRMAR_AGENDA
      : FLUJO_VIDEOLLAMADA_CONTINUAR;

  const handleClickBotonContinuar = () => {
    if (pasoActual < 3) {
      pasoSiguiente();
    } else if (pasoActual === 3) {
      confirmarReserva();
    }
  };

  const contextoValue = useMemo(
    () => ({
      pasoActual,
      handleAceptaCondiciones,
      aceptaCondiciones,
      handleAceptaGuardarNumeroDeSerie,
      aceptaGuardarNumeroDeSerie,
      datosPersonales,
      setDatosPersonales,
      fechaSeleccionada,
      setFechaSeleccionada,
      disponibilidadSeleccionada,
      setDisponibilidadSeleccionada,
      setHorasDisponibles,
      errorCupos,
      errorVideollamadaAgendada,
    }),
    [
      pasoActual,
      handleAceptaCondiciones,
      aceptaCondiciones,
      handleAceptaGuardarNumeroDeSerie,
      aceptaGuardarNumeroDeSerie,
      datosPersonales,
      setDatosPersonales,
      fechaSeleccionada,
      setFechaSeleccionada,
      disponibilidadSeleccionada,
      setDisponibilidadSeleccionada,
      setHorasDisponibles,
      errorCupos,
      errorVideollamadaAgendada,
    ],
  );

  const handleVolverASeguridad = () => {
    dispatch(fetchMisDatos());
  };

  const generarBoton = () =>
    pasoActual === 4 ? (
      <Link to="/seguridad">
        <ButtonOutline
          handleClick={handleVolverASeguridad}
          text={IR_A_MI_PERFIL}
          className={styles.botonPerfil}
        />
      </Link>
    ) : (
      <ButtonSolid
        text={generarTextoBoton()}
        ariaLabel={FLUJO_VIDEOLLAMADA_CONTINUAR}
        handleClick={handleClickBotonContinuar}
        isDisabled={!pasosCompletos[pasoActual]}
        isLoading={botonCargando}
        className={styles.botonContinuar}
      />
    );

  if (isLoading)
    return (
      <CardSpinner
        spin
        text="Cargando..."
        className="spinner__transparent__videollamada"
      />
    );

  return (
    <div className={styles.container}>
      {pasoActual > 0 && pasoActual <= 3 && (
        <Stepper
          steps={pasos}
          currentStep={pasoActual}
          handleClick={cambiarPaso}
        />
      )}
      <ContextoAgenda.Provider value={contextoValue}>
        {pasoActual === 0 && <Requisitos />}
        {pasoActual === 1 && (
          <DatosPersonales
            pasosCompletos={pasosCompletos}
            setPasosCompletos={setPasosCompletos}
            erroresDnic={erroresPaso1}
            setErroresDnic={setErroresPaso1}
            setLlamarDnic={setLlamarDnic}
            usuarioLogueado={usuarioLogueado}
          />
        )}
        {pasoActual === 2 && (
          <PasoAgenda
            fechasDisponibles={fechasDisponibles}
            horasDisponibles={horasDisponibles}
            obtenerHorasPorFecha={obtenerHorasPorFecha}
          />
        )}
        {pasoActual === 3 && <Confirmacion />}
        {pasoActual === 4 && <ExitoReserva />}
      </ContextoAgenda.Provider>
      {generarBoton()}
    </div>
  );
};

export default Agenda;
