import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CardSmall from 'components/CardSmall';
import CardSpinner from 'components/CardSpinner';
import { CONTACT_VIDEOLLAMADA_CANCEL } from 'constants/pageTitles';
import { cancelarConsultaVideollamada } from 'services/apiServices';
import ButtonSection from 'components/ButtonSection';
import { ERROR_ROUTE, INDEX_ROUTE } from 'constants/RouterConstants';
import {
  VIDEOLLAMADA_CANCELADA,
  VIDEOLLAMADA_CANCELADA_DETALLE,
  VOLVER_AL_INICIO,
} from 'constants/commonConstants';

const CancelarConsulta = () => {
  const [cargando, setCargando] = useState(true);
  const [errors, setErrors] = useState(false);
  const history = useHistory();

  const handleVolverAlInicio = () => {
    history.push(INDEX_ROUTE);
  };

  const cancelarVideollamada = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const uuid = urlSearchParams.get('videollamada');
    try {
      await cancelarConsultaVideollamada(uuid);
    } catch (error) {
      setErrors(true);
    }
  };

  useEffect(() => {
    cancelarVideollamada();
    setCargando(false);
  }, []);

  if (cargando) {
    return <CardSpinner spin />;
  }

  if (errors) {
    history.push(ERROR_ROUTE);
  }

  return (
    <>
      <Helmet title={CONTACT_VIDEOLLAMADA_CANCEL} />
      <CardSmall
        title={VIDEOLLAMADA_CANCELADA}
        desc={VIDEOLLAMADA_CANCELADA_DETALLE}
      >
        <ButtonSection
          textPrimary={VOLVER_AL_INICIO}
          handleClickPrimary={handleVolverAlInicio}
        />
      </CardSmall>
    </>
  );
};

export default CancelarConsulta;
